import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable, ErrorHandler, APP_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';

import { HttpClientModule } from '@angular/common/http';
import { AuthAPIService } from './home/services/auth-api.service';

import { environment } from '../environments/environment';

import * as Sentry from '@sentry/browser';
import { RouterExtService } from './routerExt.service';
import { CanDeactivateGuard } from './home/can-deactivate/can-deactivate.guard';
import { UserSessionService } from './home/services/user-session.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslocoRootModule } from './transloco-root.module';
import { REQUEST } from './tokens/tokens';
import { request } from 'express';
import { MAT_DATE_LOCALE } from '@angular/material/core';
if (environment.sentry_dsn) {
  Sentry.init({
    dsn: environment.sentry_dsn,
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error?.originalError || error);
    throw error;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    RecaptchaV3Module,
    AngularSvgIconModule.forRoot(),
    TranslocoRootModule,
  ],
  exports: [AppRoutingModule],
  providers: [
    UserSessionService,
    provideClientHydration(),
    AuthAPIService,
    RouterExtService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    CanDeactivateGuard,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha },
    { provide: APP_ID, useValue: 'my-app' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private routerExtService: RouterExtService) {}
}
